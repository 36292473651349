import moment from 'moment';
import 'moment-business-days';
import PropTypes from 'prop-types';
import React from 'react';
import { Form } from 'react-bootstrap';
import Datetime from 'react-datetime';
import Errors from './Errors';
import styled from 'styled-components';
import { formatDisplayDate } from 'helpers/DateHelpers';

const DateContainer = styled.div`
  ${(props) =>
    props.inline &&
    `
    display: flex;
    gap: 1rem;
    width: 100%;
  `}
`;

const DateTimeInput = styled(Datetime)`
  ${(props) =>
    props.directionUp &&
    `
    .rdtPicker {
      top: -270px;
    }
  `}
`;

const DateRange = ({
  controlIdStartAt,
  controlIdStopAt,
  readOnly = false,
  required = false,
  startLabel = 'Start date',
  stopLabel = 'Stop date',
  placeholder = { start_datetime: undefined, stop_datetime: undefined },
  value = { start_datetime: undefined, stop_datetime: undefined },
  limit = { start_datetime: undefined, stop_datetime: undefined },
  onChange = () => {},
  errors = { start_datetime: undefined, stop_datetime: undefined },
  dateTime = true,
  inline = false,
  directionUp = false,
}) => {
  const dateFormat = dateTime ? 'YYYY-MM-DD HH:mm:ss' : 'YYYY-MM-DD';

  const handleChange = (key, val) => {
    if (readOnly) {
      return;
    }

    if (moment.isMoment(val) && val.isValid()) {
      return onChange({ ...value, [key]: val.format(dateFormat) });
    }

    onChange({ ...value, [key]: val });
  };

  let initialEndDate = new Date();
  initialEndDate.setHours(12, 59, 59, 0);

  let validDateStart = (current) => {
    if (value.stop_datetime && current.isAfter(moment.utc(value.stop_datetime))) {
      return false;
    }

    if (!limit?.start_datetime) {
      return true;
    }

    const limitStart = moment.utc(limit.start_datetime);
    if (current.isBefore(limitStart)) {
      return false;
    }

    return true;
  };

  let validDateEnd = (current) => {
    if (value.start_datetime && current.isBefore(moment.utc(value.start_datetime))) {
      return false;
    }

    if (!limit?.stop_datetime) {
      return true;
    }

    const limitEnd = moment.utc(limit.stop_datetime);
    if (current.isAfter(limitEnd)) {
      return false;
    }

    return true;
  };

  return (
    <DateContainer inline>
      <Form.Group controlId={controlIdStartAt} className='w-50'>
        <Form.Label className='font-weight-bold'>{startLabel}</Form.Label>
        <DateTimeInput
          directionUp
          value={value.start_datetime ? moment.utc(value.start_datetime) : undefined}
          renderInput={(props, openCalendar) => (
            <Form.Control
              type='text'
              onClick={openCalendar}
              readOnly={readOnly}
              placeholder={
                placeholder.start_datetime
                  ? `${formatDisplayDate(moment.utc(placeholder.start_datetime))} ${
                      dateTime ? moment.utc(placeholder.start_datetime).format('hh:mm A') : ''
                    }`
                  : ''
              }
              value={
                props.value
                  ? `${formatDisplayDate(moment.utc(props.value))} ${
                      dateTime ? moment.utc(props.value).format('hh:mm A') : ''
                    }`
                  : ''
              }
            />
          )}
          autoComplete={false}
          isValidDate={validDateStart}
          timeConstraints={{
            hours: { min: 0, max: 23 },
            minutes: { min: 0, max: 59, step: 1 },
          }}
          onChange={(v) => handleChange('start_datetime', v)}
        />
        <Form.Control.Feedback type='invalid' className={errors.start_datetime ? 'd-block' : ''}>
          <Errors errors={errors.start_datetime} />
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group controlId={controlIdStopAt} className='w-50'>
        <Form.Label className='font-weight-bold'>{stopLabel}</Form.Label>
        <DateTimeInput
          directionUp
          value={value.stop_datetime ? moment.utc(value.stop_datetime) : undefined}
          renderInput={(props, openCalendar, closeCalendar) => (
            <Form.Control
              type='text'
              onClick={openCalendar}
              placeholder={
                placeholder.stop_datetime
                  ? `${formatDisplayDate(moment.utc(placeholder.stop_datetime))} ${
                      dateTime ? moment.utc(placeholder.stop_datetime).format('hh:mm A') : ''
                    }`
                  : ''
              }
              readOnly={readOnly}
              value={
                props.value
                  ? `${formatDisplayDate(moment.utc(props.value))} ${
                      dateTime ? moment.utc(props.value).format('hh:mm A') : ''
                    }`
                  : ''
              }
            />
          )}
          autoComplete={false}
          isValidDate={validDateEnd}
          timeConstraints={{
            hours: { min: 0, max: 23 },
            minutes: { min: 0, max: 59, step: 1 },
          }}
          onChange={(v) => handleChange('stop_datetime', v)}
        />
        <Form.Control.Feedback type='invalid' className={errors.stop_datetime ? 'd-block' : ''}>
          <Errors errors={errors.stop_datetime} />
        </Form.Control.Feedback>
      </Form.Group>
    </DateContainer>
  );
};

DateRange.propTypes = {
  controlIdStartAt: PropTypes.string.isRequired,
  controlIdStopAt: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  startLabel: PropTypes.string,
  stopLabel: PropTypes.string,
  value: PropTypes.shape({
    start_datetime: PropTypes.string,
    stop_datetime: PropTypes.string,
  }),
  limit: PropTypes.shape({
    start_datetime: PropTypes.string,
    stop_datetime: PropTypes.string,
  }),
  minStartDays: PropTypes.number,
  minEndDays: PropTypes.number,
  onChange: PropTypes.func,
  errors: PropTypes.shape({
    start_datetime: PropTypes.arrayOf(PropTypes.string),
    stop_datetime: PropTypes.arrayOf(PropTypes.string),
  }),
  dateTime: PropTypes.bool,
  inline: PropTypes.bool,
};

export default DateRange;

import moment from 'moment/moment';
import { formatDisplayDate } from 'helpers/DateHelpers';

export const ToolTip = (utilisation, showBy, startDate, endDate, duration) => {
  if (showBy === 'days') {
    return `<div style="width: 190px; margin: 10px">
        <p><strong>Utilisation:</strong> ${utilisation}%</p>
        <p><strong>Date:</strong> ${formatDisplayDate(startDate)}</p>
        <p><strong>Duration:</strong> ${duration} day(s)</p>
        </div>`;
  } else if (showBy === 'weeks') {
    return `<div style="width: 190px; margin: 10px">
        <p><strong>Utilisation:</strong> ${utilisation}%</p>
        <p><strong>Date:</strong> ${formatDisplayDate(startDate)} <strong>to</strong> ${formatDisplayDate(endDate)}</p>
        <p><strong>Duration:</strong> ${duration} day(s)</p>
        </div>`;
  } else {
    return `<div style="width: 190px; margin: 10px">
        <p><strong>Utilisation:</strong> ${utilisation}%</p>
        <p><strong>Month:</strong> ${moment(startDate).format('MMMM')}</p>
        <p><strong>Date:</strong> ${formatDisplayDate(startDate)} <strong>to</strong> ${formatDisplayDate(endDate)}</p>
        <p><strong>Duration:</strong> ${duration} day(s)</p>
        </div>`;
  }
};

import store from '../app/store';
import { FALLBACK_CURRENCY } from './financial';

export const TRANSACTIONS_TYPE_CREDIT = 'credit';
export const TRANSACTIONS_TYPE_DEBIT = 'debit';

export const getCurrencySymbol = (currency) => {
  const state = store.getState();
  const currencies = state?.session?.data?.currencies ?? [];
  return currencies.find((c) => c.code === currency)?.symbol ?? '';
};

export const formatWalletNumber = (number, currency = FALLBACK_CURRENCY) => {
  return new Intl.NumberFormat(undefined, {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
  }).format(number);
};

import { useMemo } from 'react';
import { faFacebookF, faGoogle, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Badge, Image } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  isArchived,
  isLive,
  STATUS_APPROVED,
  STATUS_COMPLETED,
  STATUS_DRAFT,
  STATUS_LIVE,
  STATUS_NOT_APPROVED,
  STATUS_PAUSED,
  STATUS_PENDING_APPROVAL,
} from '../../../lib/orders';
import { selectOrderStatuses, selectUser } from '../../session/sessionSlice';
import CircularBadge from './CircularBadge';
import styles from './order_square.module.scss';
import moment from 'moment';
import { faGrid2 } from '@fortawesome/pro-regular-svg-icons';
import { formatDisplayDate } from 'helpers/DateHelpers';

const colours = {
  [STATUS_PENDING_APPROVAL]: 'rgb(239 222 204)',
  [STATUS_LIVE]: 'rgb(195, 213, 190)',
  [STATUS_APPROVED]: 'rgb(195, 213, 190)',
  [STATUS_NOT_APPROVED]: 'rgb(255 198 195)',
  [STATUS_COMPLETED]: 'rgb(241, 241, 241)',
  [STATUS_PAUSED]: '#f4f1d6',
  [STATUS_DRAFT]: 'rgb(224 220 236)',
  archived: 'rgb(241, 241, 241)',
};

const platformToIcons = {
  facebook: faFacebookF,
  google: faGoogle,
  youtube: faYoutube,
  native: faGrid2,
  bespoke_product: faGrid2,
};

const OrderSquare = ({ order }) => {
  const user = useSelector(selectUser);

  const start_date = moment.utc(order.start_at);
  const stop_date = moment.utc(order.stop_at);
  const colourStatus = isArchived(order, user) ? 'archived' : order.status;
  const orderStatuses = [...useSelector(selectOrderStatuses), { id: 'archived', label: 'Archived' }];
  const orderStatus = orderStatuses.find((status) => colourStatus === status.id);
  const isOrderLive = isLive(order);

  const platformIcons = [...new Set(order.data_json.map((ad) => ad.platform))].map((platform) => ({
    id: platform,
    icon: platformToIcons[platform] || faGrid2,
  }));

  const isOwner = order.owner_id === user.active_organisation.id;

  const previewUrl = useMemo(() => {
    if (order.preview_url) {
      return order.preview_url;
    }

    return '/ad_preview/sample.jpg';
  }, [order]);

  return (
    <Link to={`/order/${order.id}`} className={styles.link}>
      <div className={styles.figure}>
        <div
          className='position-relative d-flex justify-contents-center rounded-top'
          style={{ border: `1px solid ${colours[colourStatus]}` }}
        >
          <Image className={styles.img} src={previewUrl} alt='Order image' />
          <div className={styles.platform_icons}>
            {platformIcons.map((icon) => (
              <FontAwesomeIcon key={icon.id} className={styles.platform_icon_filter} icon={icon.icon} size='sm' />
            ))}
          </div>
        </div>
        <div className={`${styles.caption} p-2 w-100`} style={{ backgroundColor: colours[colourStatus] }}>
          <p className={styles.title} title={order.name}>
            {order.name}
          </p>
          <small className={styles.date}>
            {isOwner ? `Supplier: ${order?.supplier?.name}` : `Retailer: ${order?.owner?.name}`}
          </small>
          <div className='d-flex align-items-end'>
            <small className={styles.date}>
              {formatDisplayDate(start_date)} - {formatDisplayDate(stop_date)}
            </small>

            <span className='ml-auto mr-2'>{isOrderLive && <CircularBadge />}</span>
            <span style={{ lineHeight: '1' }}>
              {orderStatus && (
                <Badge className={styles.status_badge} style={{ backgroundColor: 'white' }}>
                  {orderStatus.label}
                </Badge>
              )}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};

export default OrderSquare;

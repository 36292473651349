import { faBook, faList, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { faMoneyBill, faPlus } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Col, Container, Form, InputGroup, Modal, OverlayTrigger, Row, Tab, Tooltip } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { getCampaignInsightsBreakdown, uploadPOE } from '../../lib/api';
import { videoErrors } from '../../lib/asset_selection';
import { STATUS_APPROVED, STATUS_COMPLETED, STATUS_LIVE, STATUS_PAUSED } from '../../lib/orders';
import { clearSession, selectAuthToken, selectSession, selectUserOrganisationCurrency } from '../session/sessionSlice';
import AdContainer from './AdContainer';
import styles from './ad_section.module.scss';
import AdDateRange from './components/AdDateRange';
import DynamicField from './components/DynamicField';
import Errors from './components/Errors';
import Preview from './components/Preview';
import {
  assetsUpdated,
  budgetUpdated,
  clearOrder,
  errorsUpdate,
  hasLiveOrderBeingViewed,
  orderUpdated,
  selectAssets,
  selectOrder,
  selectOrderAds,
  selectOwnerOrganisation,
  showBidAmount,
  showBudget,
  updateOrderFormOrganisations,
} from './orderFormSlice';
import styled from 'styled-components';
import { property, sortBy } from 'lodash';
import AdGrid from './components/AdGrid';
import PreviewSection from './components/PreviewSection';
import PropertiesSection from './components/PropertiesSection';
import PropertiesBody from './components/PropertiesBody';
import { packageFieldHumanDateRangeText, packageBudgetRange } from '../../lib/packages';
import { faCalendar, faCheck } from '@fortawesome/pro-regular-svg-icons';
import MediaFileView from './components/standard_ads/AssetsPane/MediaFileView';
import BespokeProductAssetFileDropzone from './containers/BespokeProductAssetFileDropzone';
import moment from 'moment';
import { BESPOKE_DIGITAL, BESPOKE_PHYSICAL } from '../../lib/constants';
import { currencySymbol, discountText, formatToCurrency } from '../../lib/financial';
import DateSelect from '../organisation_settings/components/DateSelect';
import Clipboard from '../../app/components/Clipboard';
import { formatDisplayDate } from 'helpers/DateHelpers';

const ColorCheckbox = styled(Form.Check)`
  background: ${(props) => props.stylecolor};
  ${(props) =>
    props.stylechecked &&
    `outline: 1px solid blue;
   outline-offset: 1px;`}
  display: inline-block;
  padding: 0px !important;
  margin-right: 10px;
  input {
    display: none;
  }
  label {
    padding: 8px 12px;
    color: transparent;
  }
`;

const StyledList = styled.div`
  .form-check {
    padding: 0;
  }
  input {
    display: none;
  }
  input + label {
    border: 1px solid #000;
    border-radius: 5px;
    padding: 4px 16px;
    margin: 2px 0;
    transition: 0.3s;
  }
  input:checked + label {
    background: #7155ff;
    color: #fff;
  }
`;

/* NOTE: This is not final implementation, data eventually will come from backend like fields for each ad type  */
/* This code pushed to avoid delaying other stuff, refactor is already in place */
const propertiesTabsList = [
  {
    id: 'schedule',
    title: 'Schedule',
    description: 'When do you want your ad to run?',
    icon: faCalendar,
    completedCheck: ({ ad, errors }) => ad.start_at && ad.stop_at && !errors.start_at && !errors.stop_at,
    optional: false,
  },
  {
    id: 'ad-details',
    title: 'Content',
    description: 'How do you want your ad to look like?',
    icon: faList,
    completedCheck: ({ ad, adType, errors }) => {
      const fields = adType.fields
        .filter((field) => field.type !== 'asset')
        .filter(
          (field) => !field.deprecated_at || !ad.created_at || moment(field.deprecated_at).isAfter(ad.created_at)
        );
      const fieldIDs = fields.map((field) => field.id);

      return fieldIDs.some((field) => ad[field]) && !fieldIDs.some((id) => errors?.[id]?.length > 0);
      //return required.every(field => ad[field.id]) && !fieldIDs.some(id => errors?.[id]?.length > 0)
    },
    optional: false,
  },
  {
    id: 'budget',
    title: 'Budget',
    description: 'How much would you like to spend?',
    icon: faMoneyBill,
    completedCheck: ({ ad, errors }) => ad.budget > 0 && !errors.budget,
    optional: false,
  },
  {
    id: 'poe',
    title: 'Execution',
    description: '',
    icon: faCheck,
    completedCheck: ({ ad }) => ad.start_at && ad.stop_at,
    optional: true,
  },
  {
    id: 'metrics',
    title: 'Metrics',
    description: 'How is the performance of your ad?',
    icon: faBook,
    completedCheck: () => false,
    optional: true,
  },
];
const BespokeProductSection = React.forwardRef((props, ref) => {
  const {
    controlId,
    readOnly = false,
    ad = {},

    isNew = false,
    errors = {},
    onChange = () => {},
  } = props;
  const adTypes = useSelector(selectSession).ad_types;
  const session = useSelector(selectSession);
  const organisation = useSelector(selectOwnerOrganisation);
  const order = useSelector(selectOrder);
  const defCurrency = useSelector(selectUserOrganisationCurrency) || 'AUD';
  const currency = currencySymbol(defCurrency);
  const userActiveOrganisation = session.user?.active_organisation;
  const authToken = useSelector(selectAuthToken);
  const dispatch = useDispatch();
  const showAdBudget = useSelector(showBudget);
  const showAdBidAmount = useSelector(showBidAmount);
  const structure = session.ad_types.find((ad_type) => ad_type.control_id === ad.type);
  const adType = structure;
  const packageOfferField = props.packageOffer?.ad_format_templates?.find((field) => field.id === ad.package_item_id);
  const packageFieldDateRangeDescription = packageFieldHumanDateRangeText(packageOfferField);
  const packageMinMaxBudget = packageBudgetRange(packageOfferField, currency);
  const bespokeProduct = adTypes.find((a) => ad.type === a.control_id);
  const orderAds = useSelector(selectOrderAds);
  const selectedAds = orderAds.filter((orderAds) => orderAds.type === ad.type);
  const adIndex = (packageOfferField ? orderAds : selectedAds).findIndex((selectedAds) => selectedAds.id === ad.id);
  const [, setInsights] = useState([]);
  const [, setDigitalProductBudget] = useState(0);
  const [physicalProductBudget, setPhysicalProductBudget] = useState(0);
  const [physicalProductQty, setPhysicalProductQty] = useState(0);
  const liveOrderViewed = useSelector(hasLiveOrderBeingViewed);
  const approvedReadOnly = liveOrderViewed;

  const handleChange = (val) => onChange({ ...ad, ...val });
  const orderAssets = useSelector(selectAssets);
  let campaign;
  let structureFields;
  let structureCustomFields;
  if (ad.platform === 'facebook') {
    campaign = order?.facebook_campaigns?.find((campaign) => campaign.order_ads_id === ad.id);
  } else if (ad.platform === 'google' || ad.platform === 'youtube') {
    campaign = order?.google_campaigns?.find((campaign) => campaign.order_ads_id === ad.id);
  }

  const showExecution = Boolean(
    order.status === STATUS_LIVE ||
      order.status === STATUS_COMPLETED ||
      order.status === STATUS_PAUSED ||
      order.status === STATUS_APPROVED
  );

  /* This code block is not final code, pushed to not delay other changes/features */

  let propertiesTabs = propertiesTabsList; //.filter(p => structure?.adtype_meta?.includes(p.id) || showMetrics && p.id === 'metrics');

  const tabs = useMemo(() => {
    let tabIDs = adType.adtype_meta.slice();

    if (showExecution) {
      tabIDs.push('poe');
    }

    return sortBy(propertiesTabs, (property) => tabIDs.indexOf(property.id)).filter((property) =>
      tabIDs.includes(property.id)
    );
  }, [propertiesTabs, showExecution, adType.adtype_meta]);

  const linkAdUrl = `${process.env.REACT_APP_API_URL}/campaign/google/link?token=${authToken}&orderAdID=${ad.id}`;

  const hasAdIDLinkClick =
    (order.status === 'approved' || order.status === 'live') &&
    session?.user?.active_organisation?.id === order.owner_id &&
    ['Noel Leeming', 'The Pistol', 'The Warehouse', 'Warehouse Stationery'].includes(
      session?.user?.active_organisation?.name
    );

  const handleAdIDLinkClick = useCallback(() => {
    if (hasAdIDLinkClick) {
      window.open(linkAdUrl, '_blank').focus();
    }
  }, [linkAdUrl, hasAdIDLinkClick]);

  if (!showAdBidAmount) {
    structureFields = structure?.fields?.filter((field) => field?.id !== 'bid_amount') || [];
  } else {
    structureFields = structure?.fields;
  }
  structureCustomFields = structure?.custom_fields;

  //const hasAssetField = structureFields.some((field) => field.type === "asset");
  const assetFieldStructures = structureFields.filter(
    (field) =>
      field?.type === 'asset' || field?.type === 'video' || field?.type === 'image' || field?.type === 'document'
  );
  const assetFieldNames = assetFieldStructures.map((field) => field.id);

  const assetValues = assetFieldNames
    .flatMap((fieldID) => ad[fieldID] || [])
    .map((assetID) => orderAssets.find((orderAsset) => orderAsset.id === assetID))
    .filter((file) => file);
  const assetErrors = assetFieldNames.flatMap((fieldName) => errors[fieldName] || []);

  let rejectMessage = order?.audits?.find((audit) => audit.activity === 'order_rejected')?.remarks || '';

  let allErrors = [...assetErrors];
  if (rejectMessage) {
    allErrors.push(rejectMessage);
  }

  if (errors?.feed) {
    allErrors.push(errors?.feed);
  }

  if (errors?.id) {
    allErrors = [...allErrors, ...errors.id];
  }

  if (errors.type) {
    allErrors = [...allErrors, ...errors.type];
  }

  if (errors.goal) {
    allErrors = [...allErrors, ...errors.goal];
  }

  if (errors.budget) {
    allErrors = [...allErrors, ...errors.budget];
  }

  useEffect(() => {
    if (
      bespokeProduct?.custom_fields?.classification === BESPOKE_DIGITAL &&
      bespokeProduct?.custom_fields?.budget_period === 'lifetime' &&
      !ad?.start_at &&
      !ad?.stop_at &&
      !ad?.budget
    ) {
      let adUpdate = {
        budget: parseFloat(bespokeProduct?.custom_fields.budget),
      };

      if (bespokeProduct?.custom_fields?.campaign_start) {
        let leadTime =
          bespokeProduct?.custom_fields?.lead_time && bespokeProduct?.custom_fields?.lead_time !== ''
            ? bespokeProduct.custom_fields.lead_time
            : 0;
        let startDate = moment(bespokeProduct.custom_fields.campaign_start, 'YYYY-MM-DD hh:mm A').isBefore(moment())
          ? moment().add(leadTime, 'days').format('YYYY-MM-DD HH:MM:ss')
          : bespokeProduct.custom_fields.campaign_start;
        adUpdate = { ...adUpdate, ...{ start_at: startDate } };
      }

      if (bespokeProduct?.custom_fields?.campaign_end) {
        adUpdate = { ...adUpdate, ...{ stop_at: bespokeProduct.custom_fields.campaign_end } };
      }

      setDigitalProductBudget(adUpdate.budget);

      handleChange(adUpdate);
      dispatch(budgetUpdated());
    } else if (
      bespokeProduct?.custom_fields?.classification === BESPOKE_DIGITAL &&
      bespokeProduct?.custom_fields?.budget_period === 'daily' &&
      !ad?.start_at &&
      !ad?.stop_at &&
      !ad?.budget
    ) {
      let adUpdate = {};

      if (bespokeProduct?.custom_fields?.campaign_start && bespokeProduct?.custom_fields?.campaign_end) {
        let days =
          moment(bespokeProduct.custom_fields.campaign_end, 'YYYY-MM-DD hh:mm A').diff(
            moment(bespokeProduct.custom_fields.campaign_start, 'YYYY-MM-DD hh:mm A'),
            'days'
          ) + 1;
        let leadTime =
          bespokeProduct?.custom_fields?.lead_time && bespokeProduct?.custom_fields?.lead_time !== ''
            ? bespokeProduct.custom_fields.lead_time
            : 0;
        let startDate = moment(bespokeProduct.custom_fields.campaign_start, 'YYYY-MM-DD hh:mm A').isBefore(moment())
          ? moment().add(leadTime, 'days').format('YYYY-MM-DD HH:MM:ss')
          : bespokeProduct.custom_fields.campaign_start;
        adUpdate = {
          start_at: startDate,
          stop_at: bespokeProduct.custom_fields.campaign_end,
          budget: parseFloat(bespokeProduct.custom_fields.budget) * days,
        };
      } else {
        adUpdate = {
          budget: parseFloat(bespokeProduct.custom_fields.budget),
        };
      }

      handleChange(adUpdate);
      dispatch(budgetUpdated());
    } else if (
      bespokeProduct?.custom_fields?.classification === BESPOKE_PHYSICAL &&
      bespokeProduct?.custom_fields?.moq &&
      bespokeProduct?.custom_fields?.price &&
      !ad?.start_at &&
      !ad?.stop_at &&
      !ad?.budget
    ) {
      let adUpdate = {
        quantity: parseFloat(bespokeProduct?.custom_fields?.moq),
        budget: parseFloat(bespokeProduct?.custom_fields?.moq * bespokeProduct?.custom_fields?.price),
      };

      if (bespokeProduct?.custom_fields?.campaign_start) {
        let leadTime =
          bespokeProduct?.custom_fields?.lead_time && bespokeProduct?.custom_fields?.lead_time !== ''
            ? bespokeProduct.custom_fields.lead_time
            : 0;
        let startDate = moment(bespokeProduct.custom_fields.campaign_start, 'YYYY-MM-DD hh:mm A').isBefore(moment())
          ? moment().add(leadTime, 'days').format('YYYY-MM-DD HH:MM:ss')
          : bespokeProduct.custom_fields.campaign_start;
        adUpdate = { ...adUpdate, ...{ start_at: startDate } };
      }

      if (bespokeProduct?.custom_fields?.campaign_end) {
        adUpdate = { ...adUpdate, ...{ stop_at: bespokeProduct.custom_fields.campaign_end } };
      }

      setPhysicalProductQty(bespokeProduct?.custom_fields?.moq);
      setPhysicalProductBudget(adUpdate.budget);
      handleChange(adUpdate);
      dispatch(budgetUpdated());
    }

    if (ad?.budget && ad?.quantity) {
      setPhysicalProductQty(ad?.quantity);
      setPhysicalProductBudget(ad?.budget);
    }

    if (order.id && ad.id && campaign) {
      getCampaignInsightsBreakdown(ad.platform, order.id, ad.id).then((resp) => setInsights(resp.data.data));
    }
  }, [
    order.id,
    ad.id,
    campaign,
    bespokeProduct?.custom_fields?.campaign_start,
    bespokeProduct?.custom_fields?.campaign_end,
  ]);

  useEffect(() => {
    if (
      bespokeProduct?.custom_fields?.classification === BESPOKE_DIGITAL &&
      bespokeProduct?.custom_fields?.budget_period === 'daily' &&
      ad?.start_at &&
      ad?.stop_at
    ) {
      let days = moment(ad.stop_at, 'YYYY-MM-DD hh:mm A').diff(moment(ad.start_at, 'YYYY-MM-DD hh:mm A'), 'days') + 1;
      let adUpdate = {
        budget: parseFloat(bespokeProduct?.custom_fields.budget) * days,
      };

      handleChange(adUpdate);
      dispatch(budgetUpdated());
    } else if (
      bespokeProduct?.custom_fields?.classification === BESPOKE_PHYSICAL &&
      physicalProductQty > 0 &&
      bespokeProduct?.custom_fields?.moq &&
      bespokeProduct?.custom_fields?.price &&
      ad?.start_at &&
      ad?.stop_at
    ) {
      let adUpdate = {
        quantity: parseFloat(physicalProductQty),
        budget: parseFloat(physicalProductQty * bespokeProduct?.custom_fields?.price),
      };

      setPhysicalProductBudget(adUpdate.budget);
      handleChange(adUpdate);
      dispatch(budgetUpdated());
    }
  }, [ad.start_at, ad.stop_at, physicalProductQty]);

  const [, setColors] = useState('');

  const updateColors = (color, id) => {
    setColors(color);
    handleChange({ [id]: color });
  };

  const getDimensionGuide = (field) => {
    let restrictions = '';
    if (field?.max_width || field?.max_height || field?.min_width || field?.min_height) {
      restrictions = '(';
      if (field?.min_width) {
        restrictions = restrictions + 'Min. Width: ' + field.min_width + 'px';
      }
      if (field?.min_height) {
        if (restrictions.length > 1) restrictions += ', ';
        restrictions = restrictions + 'Min. Height: ' + field.min_height + 'px';
      }
      if (field?.max_width) {
        if (restrictions.length > 1) restrictions += ', ';
        restrictions = restrictions + 'Max. Width: ' + field.max_width + 'px';
      }
      if (field?.max_height) {
        if (restrictions.length > 1) restrictions += ', ';
        restrictions = restrictions + 'Max. Height: ' + field.max_height + 'px';
      }
      restrictions += ')';
    }

    return restrictions;
  };

  let budgetScheme = '';

  if (structureCustomFields.classification === BESPOKE_DIGITAL) {
    budgetScheme = formatToCurrency(defCurrency, structureCustomFields.budget);
    if (structureCustomFields?.budget_set_by_supplier) {
      budgetScheme = 'Min. ' + budgetScheme;
    }
    if (structureCustomFields?.budget_period === 'daily') {
      budgetScheme = budgetScheme + '/Day';
    } else {
      budgetScheme = budgetScheme + '/Lifetime';
    }
  } else if (structureCustomFields.classification === BESPOKE_PHYSICAL) {
    budgetScheme = formatToCurrency(defCurrency, structureCustomFields?.price);
    budgetScheme = budgetScheme + '/Item';
  }
  return (
    <AdContainer
      ref={ref}
      {...props}
      header={
        <>
          <span className='font-weight-bold'>
            Ad #{adIndex + 1}: {discountText(packageOfferField)} {structure.label}&nbsp;
          </span>
          {ad.id && (
            <span
              style={{
                color: '#707070',
                cursor: hasAdIDLinkClick ? 'pointer' : undefined,
              }}
              onClick={handleAdIDLinkClick}
              target='_blank'
            >
              (#{ad.id}) <Clipboard text={ad.id} color='#707070' />
            </span>
          )}
          {ad && ad?.type === 'facebook_carousel' && (
            <span>
              <OverlayTrigger
                placement='right'
                overlay={
                  <Tooltip style={{ textAlign: 'justify' }}>
                    Add 2 to 10 images or videos for this ad. Click on the uploaded images/videos to customise the card.
                  </Tooltip>
                }
              >
                <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
              </OverlayTrigger>
            </span>
          )}
          {ad && ad?.type === 'google_responsive_search' && (
            <span>
              &nbsp;
              <small>
                <a
                  href='https://support.google.com/adspolicy/answer/6008942'
                  target='_blank'
                  className='text-sm'
                  rel='noreferrer'
                >
                  Limitations and Best Practice for Google RSA's.
                </a>
              </small>
            </span>
          )}
          {packageFieldDateRangeDescription && <div>{packageFieldDateRangeDescription}</div>}
          {packageMinMaxBudget && (
            <div>
              <span className={styles.budget + ' text-right'}>{packageMinMaxBudget}</span>
            </div>
          )}
          <Form.Control.Feedback type='invalid' className={allErrors.length > 0 ? 'd-block' : ''}>
            <Errors errors={allErrors} />
          </Form.Control.Feedback>
        </>
      }
    >
      <AdGrid>
        <PreviewSection>
          <Preview
            className='p-3'
            ad={{
              ...ad,
              headline: adType?.label,
              body: adType?.description,
            }}
            assets={adType?.placeholder_images?.large}
            orderAssets={orderAssets}
            feed={ad.feed}
            newPrev={isNew}
            onAssetsChange={(assets) => handleChange({ media: assets })}
          />
        </PreviewSection>
        <PropertiesSection>
          <PropertiesBody readOnly={readOnly} tabs={tabs} completeCheckContext={{ ad, adType, errors }}>
            <Tab.Pane eventKey='schedule'>
              <Container>
                <Row>
                  <Col>
                    <AdDateRange
                      controlIdStartAt={`${controlId}.start_at`}
                      controlIdStopAt={`${controlId}.stop_at`}
                      readOnly={readOnly}
                      startReadOnly={approvedReadOnly}
                      required
                      minStartDate={session.order_start_delay_days}
                      minEndDate={session.order_end_delay_days}
                      value={{
                        start_datetime: ad.start_at,
                        stop_datetime: ad.stop_at,
                      }}
                      errors={{
                        start_datetime: errors.start_at,
                        stop_datetime: errors.stop_at,
                      }}
                      onChange={({ start_datetime, stop_datetime }) => {
                        handleChange({
                          start_at: start_datetime,
                          stop_at: stop_datetime,
                        });
                      }}
                      dateTime={ad.platform !== 'google'}
                    />
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
            <Tab.Pane eventKey='ad-details'>
              <div className={styles.form_section}>
                <div key={property.id}>
                  <Container>
                    <Row>
                      <Col sm='12'>
                        {structureFields.map((field, index) => {
                          if (field.type === 'color') {
                            return (
                              <Form.Group>
                                <div className='d-flex align-self-center align-items-center font-weight-bold'>
                                  <span>
                                    {field.title}
                                    {field?.info && ' '}
                                    {field?.info && (
                                      <OverlayTrigger
                                        placement='left'
                                        overlay={<Tooltip id={`${controlId}-info-popover`}>{field.info}</Tooltip>}
                                      >
                                        <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
                                      </OverlayTrigger>
                                    )}
                                  </span>
                                </div>
                                {field?.notice && (
                                  <Form.Control.Feedback className='d-block text-body mb-2'>
                                    {field.notice}
                                  </Form.Control.Feedback>
                                )}

                                {Object.keys(field.options).map((option) => (
                                  <ColorCheckbox
                                    type='checkbox'
                                    id={`default-color-${index}-${field.options[option]}-${adIndex}`}
                                    label={'A'}
                                    stylechecked={ad[field.id] === field.options[option]}
                                    stylecolor={field.options[option]}
                                    onClick={(e) => {
                                      updateColors(field.options[option], field.id);
                                    }}
                                  />
                                ))}
                              </Form.Group>
                            );
                          } else if (field.type === 'asset' || field.type === 'document') {
                            let restrictions = getDimensionGuide(field);
                            return (
                              <>
                                <strong>{field.title}</strong>
                                {(field?.info || field?.notice) && (
                                  <span style={{ marginLeft: '5px' }}>
                                    {field?.info && (
                                      <OverlayTrigger
                                        placement='left'
                                        overlay={<Tooltip id={`${controlId}-info-popover`}>{field.info}</Tooltip>}
                                      >
                                        <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
                                      </OverlayTrigger>
                                    )}
                                    {field?.notice && (
                                      <Form.Control.Feedback className='d-block text-body mb-2'>
                                        {field.notice}
                                      </Form.Control.Feedback>
                                    )}
                                  </span>
                                )}
                                {restrictions !== '' && <small>{restrictions}</small>}
                                <AssetChanges
                                  ad={ad}
                                  fieldStructure={field}
                                  assetFieldNames={[field.id]}
                                  assetValues={assetValues}
                                  onChange={handleChange}
                                  errors={assetErrors}
                                  readOnly={readOnly}
                                />
                              </>
                            );
                          } else if (field.type === 'video') {
                            let restrictions = getDimensionGuide(field);
                            return (
                              <>
                                <strong>{field.title}</strong>
                                {(field?.info || field?.notice) && (
                                  <span style={{ marginLeft: '5px' }}>
                                    {field?.info && (
                                      <OverlayTrigger
                                        placement='left'
                                        overlay={<Tooltip id={`${controlId}-info-popover`}>{field.info}</Tooltip>}
                                      >
                                        <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
                                      </OverlayTrigger>
                                    )}
                                    {field?.notice && (
                                      <Form.Control.Feedback className='d-block text-body mb-2'>
                                        {field.notice}
                                      </Form.Control.Feedback>
                                    )}
                                  </span>
                                )}
                                {restrictions !== '' && <small>{restrictions}</small>}
                                <AssetChanges
                                  ad={ad}
                                  fieldStructure={field}
                                  assetFieldNames={[field.id]}
                                  assetValues={assetValues}
                                  onChange={handleChange}
                                  errors={assetErrors}
                                  readOnly={readOnly}
                                />
                              </>
                            );
                          } else if (field.type === 'date') {
                            let dateSelect = '';
                            if (field?.start_date && field?.end_date) {
                              dateSelect = `Select a date between ${formatDisplayDate(
                                field.start_date
                              )}  and  ${formatDisplayDate(field.end_date)}`;
                            } else if (field?.start_date) {
                              dateSelect = `Select date after ${formatDisplayDate(field.start_date)}`;
                            } else if (field?.end_date) {
                              dateSelect = `Select date before ${formatDisplayDate(field.end_date)}`;
                            }
                            return (
                              <>
                                <div>
                                  <strong>{field.title}</strong>
                                </div>
                                {dateSelect && dateSelect !== '' && <small>{dateSelect}</small>}
                                <DateSelect
                                  label=''
                                  header={field.title}
                                  controlId={controlId}
                                  value={ad[field.id]}
                                  onChange={(id, val) => handleChange({ [field.id]: val })}
                                  minutesStep={1}
                                  errors={errors[field.id]}
                                />
                              </>
                            );
                          } else if (field.type === 'list') {
                            return (
                              <>
                                <StyledList>
                                  <Form.Group controlId={field.id}>
                                    <Form.Label className='font-weight-bold'>
                                      {field.title} {field.required && '*'}
                                    </Form.Label>
                                    {(field.info || field.notice) && (
                                      <span style={{ marginLeft: '5px' }}>
                                        {field.info && (
                                          <OverlayTrigger
                                            placement='left'
                                            overlay={<Tooltip id={`${field.id}-info-popover`}>{field.info}</Tooltip>}
                                          >
                                            <FontAwesomeIcon icon={faQuestionCircle} size='sm' />
                                          </OverlayTrigger>
                                        )}
                                        {field.notice && (
                                          <Form.Control.Feedback className='d-block text-body mb-2'>
                                            {field.notice}
                                          </Form.Control.Feedback>
                                        )}
                                      </span>
                                    )}
                                    {Object.keys(field.options).map((opt) => (
                                      <Form.Check
                                        type='checkbox'
                                        name={field.id}
                                        disabled={readOnly}
                                        id={opt + adIndex}
                                        key={opt + adIndex}
                                        label={field.options[opt]}
                                        checked={ad[field.id] && ad[field.id].includes(field.options[opt])}
                                        isInvalid={errors.length > 0}
                                        onChange={(e) => {
                                          let newFields = ad[field.id] || [];

                                          if (!readOnly) {
                                            if (e.target.checked) {
                                              newFields = [...newFields, field.options[opt]];
                                            } else {
                                              newFields = newFields.filter((item) => item !== field.options[opt]);
                                            }
                                            handleChange({ [field.id]: newFields });
                                          }
                                        }}
                                        required={field.required}
                                        value={field.options[opt]}
                                      />
                                    ))}
                                    <Form.Control.Feedback type='invalid'>
                                      <Errors errors={errors[field.id]} />
                                    </Form.Control.Feedback>
                                  </Form.Group>
                                </StyledList>
                              </>
                            );
                          } else
                            return (
                              <DynamicField
                                key={field.id}
                                controlId={`${controlId}.${field.id}`}
                                platform={structure.platform}
                                field={field}
                                readOnly={readOnly}
                                errors={errors[field.id]}
                                meta={{ ad: ad }}
                                value={ad[field.id]}
                                onChange={(val) => handleChange({ [field.id]: val })}
                              />
                            );
                        })}
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey='budget'>
              <div className={styles.form_section}>
                <Container>
                  <Row>
                    <Col sm='12'>
                      {showAdBudget && bespokeProduct?.custom_fields?.classification === BESPOKE_DIGITAL && (
                        <Form.Group controlId={`${controlId}.budget`}>
                          <div className='d-flex'>
                            <Form.Label className='font-weight-bold'>Budget</Form.Label>
                          </div>
                          <InputGroup>
                            <InputGroup.Prepend>
                              <InputGroup.Text>
                                <strong>{currency}</strong>
                              </InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control
                              type='number'
                              className='rounded-right'
                              maxLength={9}
                              min={0}
                              readOnly={readOnly || !bespokeProduct?.custom_fields?.budget_set_by_supplier}
                              value={ad.budget}
                              isInvalid={errors?.budget?.length}
                              onChange={(e) => {
                                if (bespokeProduct?.custom_fields?.budget_set_by_supplier) {
                                  handleChange({ budget: parseFloat(e.target.value) });
                                  dispatch(budgetUpdated());
                                }
                              }}
                            />
                          </InputGroup>
                          <Form.Control.Feedback
                            type='invalid'
                            className={`${errors?.budget?.length > 0 && 'd-block'}`}
                          >
                            <Errors errors={errors?.budget} />
                          </Form.Control.Feedback>
                        </Form.Group>
                      )}
                      {bespokeProduct?.custom_fields?.classification === BESPOKE_PHYSICAL && (
                        <>
                          <Form.Label className='font-weight-bold'>
                            Quantity (Min. {structureCustomFields.moq}, Batch size {structureCustomFields.batch_size})
                          </Form.Label>
                          <InputGroup>
                            <Form.Control
                              controlId={`${controlId}.quantity`}
                              type='number'
                              className='rounded-right'
                              maxLength={9}
                              step={structureCustomFields?.batch_size > 0 ? structureCustomFields.batch_size : 1}
                              min={structureCustomFields.moq}
                              readOnly={readOnly}
                              value={physicalProductQty}
                              onChange={(qty) => {
                                setPhysicalProductQty(qty.target.value);
                                handleChange({ quantity: qty.target.value });
                              }}
                            />
                          </InputGroup>
                          {structureCustomFields.batch_size > 0 &&
                            physicalProductQty % structureCustomFields.batch_size !== 0 && (
                              <Form.Control.Feedback type='invalid' className='d-block'>
                                <Errors errors={['Quantity must be a multiple of batch size']} />
                              </Form.Control.Feedback>
                            )}
                          <Form.Control.Feedback
                            type='invalid'
                            className={`${errors?.quantity?.length > 0 && 'd-block'}`}
                          >
                            <Errors errors={errors?.quantity} />
                          </Form.Control.Feedback>
                          <div>&nbsp;</div>
                        </>
                      )}
                      {showAdBudget && bespokeProduct?.custom_fields?.classification === BESPOKE_PHYSICAL && (
                        <>
                          <Form.Label className='font-weight-bold'>{'Budget (' + budgetScheme + ')'}</Form.Label>
                          <InputGroup>
                            <Form.Control
                              type='number'
                              className='rounded-right'
                              readOnly={true}
                              value={physicalProductBudget}
                            />
                          </InputGroup>
                          <Form.Control.Feedback
                            type='invalid'
                            className={`${errors?.budget?.length > 0 && 'd-block'}`}
                          >
                            <Errors errors={errors?.budget} />
                          </Form.Control.Feedback>
                          <div>&nbsp;</div>
                        </>
                      )}
                    </Col>
                  </Row>
                </Container>
              </div>
            </Tab.Pane>
            <Tab.Pane eventKey='poe'>
              <Container>
                <Row>
                  <Col>
                    <Form.Group>
                      <DynamicField
                        key='poe_text'
                        readOnly={userActiveOrganisation.id !== organisation.id}
                        controlId={`${controlId}.poe_text`}
                        platform={structure.platform}
                        field={{
                          id: 'poe_desc',
                          type: 'textarea',
                          title: 'Additional Comments (optional)',
                          maxlen: 1000,
                          notice: 'Further supporting comments to proof of execution documentation.',
                          required: false,
                          maxlensoft: 140,
                        }}
                        errors={errors['poe_text']}
                        meta={{ ad: ad }}
                        value={ad['poe_text']}
                        onChange={(val) => handleChange({ poe_text: val })}
                      />
                    </Form.Group>
                    <Form.Group>
                      <AssetChanges
                        ad={ad}
                        fieldStructure={{
                          id: 'poe_asset',
                          type: 'asset',
                          title: 'Media',
                          default: true,
                          required: true,
                          min_width: 500,
                          max_images: 1,
                          max_videos: 1,
                          min_height: 500,
                          max_attachments: 10,
                        }}
                        assetFieldNames={['poe_asset']}
                        assetValues={assetValues}
                        onChange={handleChange}
                        errors={assetErrors}
                        readOnly={userActiveOrganisation.id !== organisation.id}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {userActiveOrganisation.id === organisation.id && (
                      <Button
                        className='ml-2'
                        variant='outline-primary'
                        onClick={(e) => {
                          e.preventDefault();
                          uploadPOE(ad, ad.id)
                            .then((resp) => {
                              dispatch(clearOrder());
                              dispatch(orderUpdated(resp.data.data));
                              dispatch(updateOrderFormOrganisations());
                            })
                            .catch((err) => {
                              if (err.response?.status === 403) {
                                dispatch(clearSession());
                              } else if (err.response?.data?.errors) {
                                dispatch(errorsUpdate(err.response.data.errors));
                              }
                            });
                        }}
                      >
                        Save
                      </Button>
                    )}
                  </Col>
                </Row>
              </Container>
            </Tab.Pane>
          </PropertiesBody>
        </PropertiesSection>
      </AdGrid>
    </AdContainer>
  );
});

BespokeProductSection.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  ad: PropTypes.shape({
    type: PropTypes.string.isRequired,
  }).isRequired,
  bespoke: PropTypes.array,
  assets: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
    })
  ),
  isNew: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  errors: PropTypes.object,
};

export default BespokeProductSection;

const AssetChanges = ({ assetFieldNames, onChange, fieldStructure, errors = [], readOnly = false, ad }) => {
  const [assetModal, setAssetModal] = useState(false);
  const dispatch = useDispatch();

  const [isUploading, setIsUploading] = useState(false);
  const videoFormats = useSelector((state) => selectSession(state).file_formats.videos);
  const imageFormats = useSelector((state) => selectSession(state).file_formats.images);
  const docFormats = useSelector((state) => selectSession(state).file_formats.documents);

  let formats = [];

  // Allow all formats for POE
  if (fieldStructure.id === 'poe_asset') {
    formats = [...imageFormats, ...docFormats, ...videoFormats];
  } else {
    switch (fieldStructure.type) {
      case 'video':
        formats = videoFormats;
        break;
      case 'document':
        formats = docFormats;
        break;
      case 'asset':
        formats = imageFormats;
        break;
    }
  }

  const orderAssets = useSelector(selectAssets);

  let assets = (ad[assetFieldNames[0]] ?? [])
    .map((assetID) => orderAssets.find((orderAsset) => orderAsset.id === assetID))
    .filter((file) => file);

  const [files, setFiles] = useState(assets);
  const fieldFiles = files[assetFieldNames[0]] ?? [];
  const allAssets = new Set([...assets, ...fieldFiles]);
  let mergedArray = Array.from(new Set(allAssets));

  const handleDelete = (assetId) => {
    let newAssets = assets.filter((asset) => asset.id !== assetId);
    onChange({
      [assetFieldNames[0]]: newAssets.map((asset) => asset.id),
    });
    setFiles({ [assetFieldNames[0]]: newAssets });
    dispatch(assetsUpdated(newAssets));
  };
  return (
    <div>
      {!readOnly && (
        <Button
          variant='outline-secondary'
          className={`py-3 mb-2 w-100 ${errors.length > 0 ? 'border-danger' : ''}`}
          onClick={(e) => setAssetModal(true)}
        >
          <FontAwesomeIcon icon={faPlus} size='4x' />
          <br />
          <h6 className='mt-1'>Attach Media</h6>
        </Button>
      )}
      {assets.map((file) => (
        <MediaFileView
          key={file.id}
          file={file}
          adId={ad?.id}
          readOnly={readOnly}
          platform={ad?.platform}
          onDelete={() => handleDelete(file.id)}
        />
      ))}

      <Modal
        show={assetModal}
        onHide={(e) => {
          setFiles(assets);
          setAssetModal(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select assets to add to the order</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BespokeProductAssetFileDropzone
            ad={ad}
            max={fieldStructure?.max_attachments ?? 10}
            value={mergedArray}
            onChange={(fs) => {
              setFiles({ [assetFieldNames[0]]: fs });
            }}
            onDelete={(file) => {
              let newAssets = mergedArray.filter((f) => f.id !== file.id);
              setFiles({ [assetFieldNames[0]]: newAssets });
            }}
            accept={formats}
            onUploadStart={(_) => setIsUploading(true)}
            onUploadEnd={(_) => setIsUploading(false)}
            videoErrors={(file) => videoErrors(fieldStructure, file)}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant='primary'
            disabled={isUploading}
            onClick={(e) => {
              if (isUploading) {
                return;
              }
              onChange({
                [assetFieldNames[0]]: fieldFiles.map((file) => file.id),
              });
              fieldFiles.forEach((file) => {
                if (!assets.some((asset) => asset.id === file.id)) {
                  assets.push(file);
                }
              });
              setAssetModal(false);
              dispatch(assetsUpdated(assets));
            }}
          >
            Insert
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

import React, { useState } from 'react';
import Footer from './Footer';
import Sidebar from './Sidebar';
import Topbar from './Topbar';
import styled from 'styled-components';

const MainBody = styled.main``;

// eslint-disable-next-line react/prop-types
const Chrome = ({ children, overflowY = 'scroll' }) => {
  const [toggled, setToggled] = useState(window.innerWidth <= 760);
  const toggleSidebar = () => setToggled(!toggled);

  if (window.screen.width >= 768 && !toggled) {
    setToggled(true);
  }

  return (
    // eslint-disable-next-line react/prop-types
    <div className='d-flex flex-column w-100'>
      <Topbar toggleSidebar={toggleSidebar} />
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: toggled ? '4rem auto' : 'auto',
          height: '100vh',
        }}
      >
        <Sidebar visible={toggled} />
        <div className='w-100' style={{ overflowY: overflowY }}>
          <MainBody className='overflow-auto' role='main'>
            {children}
          </MainBody>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Chrome;

import axios from 'axios';
import { showDialog } from '../app/appSlice';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:8000';

const instance = axios.create({
  baseURL: API_URL,
  timeout: 60000,
});

// This is to setup an already existing instance, the reason for this function is to avoid infinite loop
// when importing axios instance in /api files which in turn getting imported in each redux slice file
// TODO: refactor this once we have the session id or token stored in cookies instead of the "store"
export const axiosSetUp = (instance, store) => {
  const errorCodes = [500, 501, 502, 503, 504];

  instance.interceptors.request.use((config) => {
    const token = store.getState().session?.authToken;

    if (!!token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  });

  instance.interceptors.response.use(null, async (error) => {
    const { code, config, response: res } = error;

    // if it's not timeout error and it's not one of the supported http response codes for retry. then reject and do nothing
    if (code !== 'ECONNABORTED' && (!res || !errorCodes.includes(res.status))) {
      return Promise.reject(error);
    } else {
      // TODO: Change the message or handle each message type based on status inside the dialog after checking if it's axios error
      store.dispatch(
        showDialog({
          type: 'error',
          title: 'Something went wrong',
          body: error?.message ? error.message : 'An unrecognized error occured. Please try again after a few minutes.',
          refId: error?.refId,
        })
      );

      return Promise.reject(error);
    }
  });
};

export default instance;

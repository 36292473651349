import React, { useState } from 'react';
import Datetime from 'react-datetime';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';
import moment from 'moment';
import { formatDisplayDate } from 'helpers/DateHelpers';
import styled from 'styled-components';

const TRow = styled.tr`
  td {
    min-width: 67px;
  }
`;

export const EventRow = ({
  event,
  portraitAvailable,
  landscapeAvailable,
  bundleAvailable,
  disabled = false,
  isSaving,
  onSave,
  onDelete,
}) => {
  const [editedEvent, setEditedEvent] = useState(event);
  const isValidStartDate = (current) => current.isSameOrAfter(moment(), 'day');
  const isValidEndDate = (current) => current.isSameOrAfter(editedEvent?.start_date, 'day');
  const priorityLevels = [
    { value: 2, label: 'High' },
    { value: 3, label: 'Highest' },
  ];

  const handleSave = () => {
    onSave(editedEvent);
  };

  return (
    <TRow>
      <td>
        <Form.Control
          type='text'
          disabled={isSaving || disabled}
          value={editedEvent.name}
          onChange={(e) => setEditedEvent({ ...editedEvent, name: e.target.value })}
        />
      </td>
      <td>
        <Datetime
          value={editedEvent.start_date}
          dateFormat='YYYY-MM-DD'
          renderInput={(props, openCalendar) => {
            // Format the display value for the user without affecting the underlying value format
            return <Form.Control {...props} value={formatDisplayDate(props.value)} onClick={openCalendar} />;
          }}
          timeFormat={false}
          isValidDate={isValidStartDate}
          inputProps={{ disabled: isSaving || disabled }}
          onChange={(value) => {
            const date = moment.isMoment(value) ? moment(value.toString()).format('YYYY-MM-DD') : value;
            setEditedEvent({ ...editedEvent, start_date: date });
          }}
        />
      </td>
      <td>
        <Datetime
          value={editedEvent.end_date}
          dateFormat='YYYY-MM-DD'
          renderInput={(props, openCalendar) => {
            // Format the display value for the user without affecting the underlying value format
            return <Form.Control {...props} value={formatDisplayDate(props.value)} onClick={openCalendar} />;
          }}
          timeFormat={false}
          isValidDate={isValidEndDate}
          inputProps={{ disabled: isSaving || disabled }}
          onChange={(value) => {
            const date = moment.isMoment(value) ? moment(value.toString()).format('YYYY-MM-DD') : value;
            setEditedEvent({ ...editedEvent, end_date: date });
          }}
        />
      </td>
      <td>
        {portraitAvailable ? (
          <Form.Control
            type='number'
            value={editedEvent?.prices?.Portrait}
            disabled={isSaving || disabled}
            onChange={(e) =>
              setEditedEvent({ ...editedEvent, prices: { ...editedEvent?.prices, Portrait: Number(e.target.value) } })
            }
          />
        ) : (
          'N/A'
        )}
      </td>
      <td>
        {landscapeAvailable ? (
          <Form.Control
            type='number'
            value={editedEvent?.prices?.Landscape}
            disabled={isSaving || disabled}
            onChange={(e) =>
              setEditedEvent({ ...editedEvent, prices: { ...editedEvent?.prices, Landscape: Number(e.target.value) } })
            }
          />
        ) : (
          'N/A'
        )}
      </td>
      <td>
        {bundleAvailable ? (
          <Form.Control
            type='number'
            value={editedEvent?.prices['Landscape_&_Portrait'] ?? 0}
            disabled={isSaving || disabled}
            onChange={(e) =>
              setEditedEvent({
                ...editedEvent,
                prices: { ...editedEvent?.prices, 'Landscape_&_Portrait': Number(e.target.value) },
              })
            }
          />
        ) : (
          'N/A'
        )}
      </td>
      <td>
        <Select
          placeholder='Select'
          // styles={selectStyles}
          value={priorityLevels.find((r) => r.value === editedEvent?.priority)}
          isDisabled={isSaving || disabled}
          onChange={(e) => setEditedEvent({ ...editedEvent, priority: e.value })}
          options={priorityLevels}
        />
      </td>
      <td>
        <div style={{ display: !isSaving ? 'block' : 'none' }}>
          <FontAwesomeIcon icon={faSave} onClick={handleSave} style={{ cursor: 'pointer', marginRight: '10px' }} />
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => onDelete(editedEvent.id)}
            style={{ cursor: 'pointer', marginRight: '10px' }}
          />
        </div>
      </td>
    </TRow>
  );
};

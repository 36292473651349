import React, { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
//minimal audience type
interface Audience {
  id: string;
  name: string;
  subtype: string;
  audience_type: string;
  retention_days: number;
  estimated_users: {
    lower_bound: number;
    upper_bound: number;
  };
  approximate_count_lower_bound: number;
  approximate_count_upper_bound: number;
}
type AudienceArray = Array<Audience>;

export default function FacebookAudienceTable({
  audiences,
  title,
  value,
  readOnly = false,
  onChange,
  maxOne = false,
}: {
  audiences: AudienceArray;
  title: string;
  value: AudienceArray;
  readOnly: boolean;
  maxOne?: boolean;
  onChange: (audience: Audience | AudienceArray, checked: boolean) => void;
}) {
  const [checkedAll, setAllChecked] = useState(false);
  const formatAudience = (number: number) => new Intl.NumberFormat().format(number);

  const isEqual = useCallback((audiences: AudienceArray, selectedAudience: AudienceArray) => {
    if (audiences?.length !== selectedAudience?.length || audiences.length === 0 || selectedAudience.length === 0)
      return false;
    const audiencesSorted = [...audiences].sort((a, b) => a.id.localeCompare(b.id));
    const selectedAudiencesSorted = [...selectedAudience].sort((a, b) => a.id.localeCompare(b.id));
    return audiencesSorted.every((obj, index) => {
      const compareObj = selectedAudiencesSorted[index];
      return obj.id === compareObj.id;
    });
  }, []);
  const handleChange = (audience: Audience | AudienceArray, checked: boolean) => {
    if (checked) {
      onChange(audience, true);
    } else {
      onChange(audience, false);
    }
  };
  useEffect(() => {
    setAllChecked(isEqual(audiences, value));
  }, [audiences, value, isEqual]);

  return (
    <Container>
      <Title>{title}</Title>
      <Table>
        <thead>
          <tr>
            <th>
              {maxOne ? <></> : (
                <input
                  type='checkbox'
                  name={title.replace(' ', '-')}
                  readOnly={readOnly}
                  disabled={readOnly}
                  checked={checkedAll}
                  onChange={(event) => handleChange(audiences, event.target.checked)}
                />
              )}
            </th>
            <th>Audience</th>
            <th>Estimated Size</th>
          </tr>
        </thead>
        <tbody>
          {audiences.map((audience) => {
            return (
              <tr key={audience.id}>
                <td>
                  <input
                    type='checkbox'
                    name={audience.name}
                    checked={!!value?.find((e) => e.id === audience.id)}
                    readOnly={readOnly}
                    disabled={readOnly}
                    onChange={(event) => handleChange(audience, event.target.checked)}
                  />
                </td>
                <td>{audience.name}</td>
                <td>
                  {audience?.estimated_users?.lower_bound ? formatAudience(audience.estimated_users.lower_bound) : '-'}{' '}
                  -{' '}
                  {audience?.estimated_users?.upper_bound ? formatAudience(audience.estimated_users.upper_bound) : '-'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </Container>
  );
}
const Container = styled.div`
  margin-bottom: 1.5em;
`;
const Title = styled.h3`
  font-size: 1.125rem;
  font-style: normal;
  font-weight: 600;
  line-height: 115%; /* 1.29375rem */
  margin-bottom: 1em;
  text-transform: capitalize;
  color: #5a5c69;
`;
const Table = styled.table`
  width: 100%;
  box-sizing: border-box;
  input[type='checkbox'] {
    color: #ffffff;
    accent-color: #7155ff;
    vertical-align: middle;
    width: var(--3, 1rem);
    height: var(--3, 1rem);
    flex-shrink: 0;
    &:disabled {
      accent-color: #d8d8d8;
    }
  }
  thead tr th {
    overflow: hidden;
    color: #fff;
    text-overflow: ellipsis;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5rem;
    background: #3a3b4f;
    padding: 0.75rem var(--2, 1rem);
    vertical-align: middle;
    &:nth-of-type(2) {
      border-left: 1px solid #b9b9c0;
      border-right: 1px solid #b9b9c0;
      width: 50%;
    }
    &:nth-of-type(3) {
      width: 50%;
    }
  }
  td {
    padding: 16px;
  }
  tr th,
  tr td {
    &:first-child {
      width: 56px;
    }
  }
  tbody tr {
    border-bottom: 1px solid #f2efff;
  }
  tbody tr td {
    overflow: hidden;
    color: #00001e;
    text-overflow: ellipsis;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: 115%;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'react-bootstrap';
import GoogleRemarketingAudiences from '../components/audience/GoogleRemarketingAudiences';
import SelectAudience from '../components/SelectAudience';
import FacebookAudienceTable from '../../../features/order_form/components/audience/FacebookAudienceTable';
import styled from 'styled-components';

const FormSection = styled.div`
  max-height: 500px;
  overflow: auto;
`;
export const AudiencesTab = ({
  controlId,
  readOnly,
  ad,
  structure,
  facebookCustomAudiences,
  facebookLookalikeAudiences,
  facebookSavedAudiences,
  googleRemarketingAudiences,
  handleChange,
  order,
}) => {
  return (
    <FormSection>
      <Container>
        <Row>
          <Col sm='12'>
            {structure.audiences.includes('facebook_custom_audiences') && facebookCustomAudiences.length > 0 && (
              <FacebookAudienceTable
                audiences={facebookCustomAudiences}
                title='Custom Audiences'
                value={ad.selected_audience?.facebook_custom_audiences}
                readOnly={readOnly}
                onChange={(item, checked) => {
                  if (!item) return;
                  let facebook_custom_audiences = ad.selected_audience?.facebook_custom_audiences || [];
                  if (checked) {
                    if (Array.isArray(item)) {
                      facebook_custom_audiences = [...item];
                    } else {
                      facebook_custom_audiences = [...facebook_custom_audiences, item];
                    }
                  } else {
                    if (Array.isArray(item)) {
                      facebook_custom_audiences = [];
                    } else {
                      facebook_custom_audiences = facebook_custom_audiences.filter((e) => e.id !== item?.id);
                    }
                  }
                  handleChange({
                    selected_audience: {
                      ...ad.selected_audience,
                      facebook_custom_audiences,
                      facebook_saved_audiences: [],
                    },
                  });
                }}
              />
            )}
            {structure.audiences.includes('facebook_lookalike_audiences') && facebookLookalikeAudiences.length > 0 && (
              <FacebookAudienceTable
                audiences={facebookLookalikeAudiences}
                title='Lookalike Audiences'
                readOnly={readOnly}
                value={ad.selected_audience?.facebook_lookalike_audiences}
                onChange={(item, checked) => {
                  if (!item) return;
                  let facebook_lookalike_audiences = ad.selected_audience?.facebook_lookalike_audiences || [];
                  if (checked) {
                    if (Array.isArray(item)) {
                      facebook_lookalike_audiences = [...item];
                    } else {
                      facebook_lookalike_audiences = [...facebook_lookalike_audiences, item];
                    }
                  } else {
                    if (Array.isArray(item)) {
                      facebook_lookalike_audiences = [];
                    } else {
                      facebook_lookalike_audiences = facebook_lookalike_audiences.filter((e) => e.id !== item?.id);
                    }
                  }
                  handleChange({
                    selected_audience: {
                      ...ad.selected_audience,
                      facebook_lookalike_audiences,
                      facebook_saved_audiences: [],
                    },
                  });
                }}
              />
            )}
            {structure.audiences.includes('facebook_saved_audiences') && facebookSavedAudiences.length > 0 && (
              <FacebookAudienceTable
                audiences={facebookSavedAudiences}
                title='Saved Audiences (Max. 1)'
                maxOne={true}
                readOnly={readOnly}
                value={ad.selected_audience?.facebook_saved_audiences}
                onChange={(item, checked) => {
                  if (!item) return;
                  let facebook_saved_audiences = ad.selected_audience?.facebook_saved_audiences || [];
                  if (checked) {
                    facebook_saved_audiences = [item];
                  } else {
                    facebook_saved_audiences = facebook_saved_audiences.filter((e) => e.id !== item?.id);
                  }
                  handleChange({
                    selected_audience: {
                      ...ad.selected_audience,
                      facebook_custom_audiences: [],
                      facebook_lookalike_audiences: [],
                      facebook_saved_audiences,
                    },
                  });
                }}
              />
            )}
            {structure.audiences.includes('google_remarketing_audience') && googleRemarketingAudiences.length > 0 && (
              <GoogleRemarketingAudiences
                controlId={`${controlId}-google-remarketing-audiences`}
                readOnly={readOnly}
                disabled={readOnly}
                platform={ad.platform}
                audiences={googleRemarketingAudiences}
                value={ad.selected_audience?.google_remarketing_audiences}
                onChange={(item, checked) => {
                  if (!item) return;
                  let google_remarketing_audiences = ad.selected_audience?.google_remarketing_audiences || [];
                  if (checked) {
                    google_remarketing_audiences = [...google_remarketing_audiences, item];
                  } else {
                    google_remarketing_audiences = google_remarketing_audiences.filter((e) => e.id !== item?.id);
                  }
                  handleChange({
                    selected_audience: {
                      ...ad.selected_audience,
                      google_remarketing_audiences,
                    },
                  });
                }}
              />
            )}

            <SelectAudience
              controlId={`${controlId}-select-audience`}
              readOnly={readOnly}
              adType={ad.type}
              platform={ad.platform}
              order={order}
              value={ad.selected_audience?.standard_audience}
              audience={ad.audience}
              adAudiences={structure.audiences}
              onChange={(audience) => {
                handleChange({
                  audience,
                  selected_audience: {
                    ...ad.selected_audience,
                    standard_audience: audience, // Auto select the standard audience
                  },
                });
              }}
              onSelect={(standard_audience) => {
                handleChange({
                  selected_audience: {
                    ...ad.selected_audience,
                    standard_audience,
                  },
                });
              }}
            />
          </Col>
        </Row>
      </Container>
    </FormSection>
  );
};

AudiencesTab.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  ad: PropTypes.object.isRequired,
  structure: PropTypes.object.isRequired,
  facebookCustomAudiences: PropTypes.array.isRequired,
  facebookLookalikeAudiences: PropTypes.array.isRequired,
  facebookSavedAudiences: PropTypes.array.isRequired,
  googleRemarketingAudiences: PropTypes.array.isRequired,
  handleChange: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired,
};

import React, { useCallback, useEffect, useState } from 'react';
import propTypes from 'prop-types';
import { formatWalletNumber, TRANSACTIONS_TYPE_CREDIT, TRANSACTIONS_TYPE_DEBIT } from '../../lib/wallets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faDownload,
  faMinus,
  faMinusSquare,
  faPlus,
  faPlusSquare,
  faWallet,
} from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';

import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { useRequest } from '../../lib/hooks/useRequest';
import { getWalletTransactions, getTransactionReportUrl } from '../../lib/api/wallets';
import QueryResult from './QueryResult';
import AddCreditDebitNoteDialog from './AddCreditDebitNoteDialog';
import moment from 'moment';
import { Button, OverlayTrigger, Tooltip, Spinner } from 'react-bootstrap';
import { trimEllipses } from '../../lib/strings';
import CurrencyBadge from './CurrencyBadge';
import DataTable from './components/tables/DataTable';
import { formatDisplayDate } from 'helpers/DateHelpers';

const DateTimeDisplay = styled.div`
  text-align: right;
  white-space: nowrap;
`;

const StyledBalance = styled.p`
  margin: 0;
  font-weight: bold;
  color: #7155ff;
`;

const StyledBtn = styled(Button)`
  ${({ fontSize }) => fontSize && `width: ${fontSize}`}
  ${({ width }) => width && `width: ${width}`}
  min-width: 160px;
`;

const StyledBackToWallet = styled.span`
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
`;

const StyledTooltip = styled(Tooltip)`
  .tooltip-inner {
    width: 100%;
    max-width: 500px;
    text-align: left;
  }
`;

const StyledDataTableWrapper = styled.div`
  .table thead th:not(:first-of-type),
  .table tbody td:not(:first-of-type) {
    max-width: 115px;
    text-align: right;
  }
`;

const StyledOrderRemark = styled.span`
  cursor: pointer;
  color: #000;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const isOrderRelatedTransaction = (data) => {
  const orderActions = ['order_approval'];
  return orderActions.includes(data?.action) && data?.entity_id?.length > 0;
};

const Transactions = ({ walletID = null, changeView, isSupplier = false }) => {
  const [showCreditNoteDialog, setShowCreditNoteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showDebitNoteDialog, setShowDebitNoteDialog] = useState(false);

  const [transactions, setTransactions] = useState([]);
  const [wallet, setWallet] = useState({});
  const navigate = useNavigate();

  const { response, loading, error, refresh } = useRequest(() => getWalletTransactions(walletID));

  const handleTransactionDownload = useCallback(async () => {
    setIsLoading(true);
    const response = await getTransactionReportUrl(walletID);
    let reportUrl = response?.data?.data?.url;
    if (reportUrl) {
      window.open(reportUrl);
    }
    setIsLoading(false);
  }, [walletID]);

  const onSubmitHandler = useCallback(() => {
    setShowCreditNoteDialog(false);
    setShowDebitNoteDialog(false);
    refresh();
  }, []);

  useEffect(() => {
    if (response !== null) {
      const data = response?.data;
      setTransactions(data.transactions);
      setWallet(data.wallet);
    }
  }, [response]);

  const transactionListColumns = [
    {
      dataField: 'remark',
      text: 'Description',
      formatter: (cell, row) => {
        return (
          <p className='mb-0 d-flex align-items-center'>
            {row?.type === 'credit' ? (
              <FontAwesomeIcon icon={faPlusSquare} color='#4FAD80' size='lg' className='mr-2' />
            ) : (
              <FontAwesomeIcon icon={faMinusSquare} color='#EF767A' size='lg' className='mr-2' />
            )}
            <OverlayTrigger placement='bottom' overlay={<StyledTooltip>{row?.remark}</StyledTooltip>}>
              {isOrderRelatedTransaction(row) ? (
                <StyledOrderRemark onClick={() => navigate(`/order/${row?.entity_id}`)}>
                  <div>
                    <div>Order approval</div>
                    <div style={{ fontSize: '11px', fontWeight: 'normal' }}>#{row?.entity_id}</div>
                  </div>
                </StyledOrderRemark>
              ) : (
                <span>{trimEllipses(row?.remark, 60)}</span>
              )}
            </OverlayTrigger>
          </p>
        );
      },
    },
    {
      dataField: 'created_at',
      text: 'Date / Time',
      formatter: (cell, row) => {
        return (
          <DateTimeDisplay>
            <div className='d-flex flex-column'>
              <div> {formatDisplayDate(row.created_at)}</div>
              <div> {moment(row.created_at).format('HH:mm')}</div>
            </div>
          </DateTimeDisplay>
        );
      },
    },
    {
      dataField: 'created_by',
      text: 'User Name',
      formatter: (cell, row) => {
        return row?.creator_name;
      },
    },
    {
      dataField: 'amount',
      text: 'Amount',
      formatter: (cell, row) => {
        const type = row?.type === 'credit' ? '+' : '-';
        return `${type}${formatWalletNumber(row?.amount, row?.currency)}`;
      },
    },
    {
      dataField: 'wallet_balance_after',
      text: 'Net Position',
      formatter: (cell, row) => formatWalletNumber(row?.wallet_balance_after, row?.currency),
    },
    {
      dataField: 'action',
      text: 'Action',
      formatter: (cell, row) => {
        if (row.action.toLowerCase() === 'manual') {
          return row.type === 'credit' ? 'Credit Note' : row.type === 'debit' ? 'Debit Note' : '—';
        }
        return _.startCase(row?.action);
      },
    },
  ];

  return (
    <QueryResult loading={loading} error={error}>
      <>
        <div>
          <StyledBackToWallet onClick={changeView}>
            <FontAwesomeIcon icon={faChevronLeft} size='md' color='#000' className='mr-2' />
            <span>Back to all wallets</span>
          </StyledBackToWallet>
        </div>

        <div className='d-flex justify-content-between align-items-center mb-3 mt-4'>
          <div>
            <FontAwesomeIcon icon={faWallet} size='md' className='mr-2' />
            <span>{wallet.name}</span>
          </div>
          <div className='d-flex flex-columnt text-right'>
            <div>
              <StyledBalance>Net Position: {formatWalletNumber(wallet.balance, wallet.currency)}</StyledBalance>
              <StyledBalance>
                Available Balance:{' '}
                {wallet.allow_overdraw ? '∞' : formatWalletNumber(wallet.available_balance, wallet.currency)}
              </StyledBalance>
            </div>
          </div>
        </div>

        <StyledDataTableWrapper>
          <DataTable
            columns={transactionListColumns}
            data={transactions}
            alwaysShowPagination={true}
            noDataIndication={() => <div>There is no transaction for this wallet</div>}
          />
        </StyledDataTableWrapper>

        <div className='d-flex justify-content-between align-items-center mt-5'>
          {transactions?.length > 0 && (
            <StyledBtn onClick={handleTransactionDownload} className='mr-3' variant='outline-primary'>
              {isLoading ? (
                <Spinner
                  animation='border'
                  variant='dark'
                  size='md'
                  style={{
                    marginRight: '5px',
                    width: '20px',
                    height: '20px',
                  }}
                />
              ) : (
                <div>
                  <FontAwesomeIcon icon={faDownload} size='md' className='mr-2' />
                  <span>Download CSV</span>
                </div>
              )}
            </StyledBtn>
          )}

          <div className='d-flex align-items-center'>
            <span className='d-inline-block mr-2'>
              Minimum Balance: {formatWalletNumber(wallet?.min_balance, wallet?.currency)}
            </span>
            <CurrencyBadge currency={wallet?.currency} width={40} />
          </div>

          {!isSupplier && (
            <div>
              <StyledBtn variant='outline-primary' onClick={() => setShowDebitNoteDialog(true)} className='mr-3'>
                <FontAwesomeIcon icon={faMinus} size='md' className='mr-2' />
                <span>Add Debit Note</span>
              </StyledBtn>
              <StyledBtn onClick={() => setShowCreditNoteDialog(true)}>
                <FontAwesomeIcon icon={faPlus} size='md' className='mr-2' />
                <span>Add Credit Note</span>
              </StyledBtn>
            </div>
          )}
        </div>

        {showCreditNoteDialog && (
          <AddCreditDebitNoteDialog
            type={TRANSACTIONS_TYPE_CREDIT}
            wallet={wallet}
            onSubmitHandler={onSubmitHandler}
            onHideHandler={() => setShowCreditNoteDialog(false)}
          />
        )}

        {showDebitNoteDialog && (
          <AddCreditDebitNoteDialog
            type={TRANSACTIONS_TYPE_DEBIT}
            wallet={wallet}
            onSubmitHandler={onSubmitHandler}
            onHideHandler={() => setShowDebitNoteDialog(false)}
          />
        )}
      </>
    </QueryResult>
  );
};

Transactions.propTypes = {
  walletID: propTypes.isRequired,
  changeView: propTypes.func.isRequired,
  refreshWallet: propTypes.func.isRequired,
  isSupplier: propTypes.bool,
};

export default Transactions;

import { debounce, uniqBy } from 'lodash';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { getProducts } from '../../../../lib/api';
import Errors from '../Errors';
import styles from './feed.module.scss';
import ProductSquare from './ProductSquare';

const GoogleFeed = ({
  controlId,
  readOnly = false,
  required = false,
  productSets = [],
  productCatalogs = [],
  value = { group: {}, products: [] },
  onChange = () => {},
  onProductsChange = () => {},
  errors = [],
}) => {
  const [products, setProducts] = useState([]);
  const [after, setAfter] = useState(undefined);
  const [filter, setFilter] = useState(''); // For text filter
  const [searchFilter, setSearchFilter] = useState(''); // Uses text filter in the payload, for debounce to stop spamming of requests.
  const [status, setStatus] = useState('idle');

  const debounceHandler = useMemo(
    () =>
      debounce((v) => {
        setAfter(undefined);
        handleProductsChange([]);
        setSearchFilter(v);
      }, 800),
    []
  );

  const handleProductsChange = (products) => {
    setProducts(products);
    onProductsChange(products);
  };

  useEffect(() => {
    if (Object.keys(value.group).length === 0) {
      handleProductsChange([]);
    } else if (productSets.some((productSet) => value.group.id === productSet.id)) {
      setStatus('loading');
      getProducts(value.group.id, searchFilter, after, 1000)
        .then((resp) => {
          if (after === undefined) {
            handleProductsChange(resp.data.data);
          } else {
            handleProductsChange(uniqBy([...products, ...resp.data.data], 'id'));
          }
          setAfter(resp.data.after);
        })
        .then((_) => setStatus('succeeded'));
    }
  }, [value.group?.id, searchFilter]);

  if (!value.group?.id && productSets.length > 0) {
    onChange({
      ...value,
      group: productSets[0],
      products: [],
    });
  }

  return (
    <div>
      <Form.Group controlId={controlId}>
        <Row className='mb-2'>
          <Col md='6'>
            <Form.Control
              as='select'
              required={required}
              readOnly={readOnly}
              disabled={readOnly}
              value={value.group?.id}
              onChange={(e) => {
                setAfter(undefined);
                handleProductsChange([]);
                if (e.target.value === '') {
                  onChange({ ...value, group: {}, products: [] });
                  return;
                }
                let set = [...productCatalogs, ...productSets];

                onChange({
                  ...value,
                  group: set.find((set) => set.id === e.target.value),
                  products: [],
                });
              }}
            >
              <option value=''>None</option>
              {productCatalogs.map((set) => (
                <option value={set.id} key={set.id}>
                  {set.name}
                </option>
              ))}
              {productSets.map((set) => (
                <option value={set.id} key={set.id}>
                  {set.name}
                </option>
              ))}
            </Form.Control>
          </Col>
          <Col md='6' className='d-flex align-items-center'>
            <Form.Control
              type='text'
              value={filter}
              placeholder='Search...'
              onChange={(e) => {
                setFilter(e.target.value);
                debounceHandler(e.target.value);
              }}
            />
          </Col>
        </Row>
        <Row>
          <Form.Control.Feedback type='invalid'>
            <Errors errors={errors} />
          </Form.Control.Feedback>
        </Row>
      </Form.Group>
      {products.length === 0 ? (
        <div className='text-center' style={{ lineHeight: '10rem' }}>
          {status === 'loading' ? (
            <Spinner animation='border' role='status'>
              <span className='sr-only'>Loading...</span>
            </Spinner>
          ) : (
            'No products available'
          )}
        </div>
      ) : (
        <div style={{ overflow: 'auto', maxHeight: '30rem' }}>
          <div className={`position-relative w-100 p-1 ${styles.filtered_products}`}>
            {products.map((product) => (
              <ProductSquare
                key={product.id}
                src={product.image_url}
                name={product.title}
                price={product.price}
                checked={value.products.some((p) => p.id === product.id)}
                onClick={(e) => {
                  if (readOnly) {
                    return;
                  }

                  if (value.products.some((p) => p.id === product.id)) {
                    onChange({
                      ...value,
                      products: value.products.filter((p) => p.id !== product.id),
                    });
                    return;
                  }

                  onChange({
                    ...value,
                    products: [...value.products, product],
                  });
                }}
              />
            ))}
          </div>
          <div className='d-flex justify-content-center'>
            {after && (
              <Button
                variant='outline-primary'
                disabled={status === 'loading'}
                onClick={(e) => {
                  setStatus('loading');
                  getProducts(value.group.id, searchFilter, after, 1000)
                    .then((resp) => {
                      if (after === undefined) {
                        handleProductsChange(resp.data.data);
                      } else {
                        handleProductsChange(uniqBy([...products, ...resp.data.data], 'id'));
                      }
                      setAfter(resp.data.after);
                    })
                    .then((_) => setStatus('succeeded'));
                }}
              >
                <span className='d-flex align-items-center'>
                  <span>View More</span>
                  {status === 'loading' && (
                    <Spinner as='span' className='ml-2' animation='border' size='sm' role='status' aria-hidden='true' />
                  )}
                </span>
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

GoogleFeed.propTypes = {
  controlId: PropTypes.string.isRequired,
  readOnly: PropTypes.bool,
  required: PropTypes.bool,
  manualInput: PropTypes.bool,
  value: PropTypes.shape({
    group: PropTypes.object,
    products: PropTypes.arrayOf(PropTypes.object),
  }),
  errors: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func,
  onProductsChange: PropTypes.func,
};

export default GoogleFeed;

import React from 'react';
import styled from 'styled-components';
import devices from '../../../lib/devices';
import { Subheader, SubheaderWrapper, Subsection } from './AdGrid';

const PropSection = styled(Subsection)`
  @media screen and (max-width: ${devices.tablet}) {
    grid-column: 1/-1;
  }
`;
const Container = styled.div`
  height:100%
`;
const PropertiesSection = ({ children }) => (
  <PropSection>
    <SubheaderWrapper>
      <Subheader>Properties</Subheader>
    </SubheaderWrapper>
    <Container>{children}</Container>
  </PropSection>
);

export default PropertiesSection;

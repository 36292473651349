import moment from 'moment';

export const DISPLAY_DATE_FORMAT = 'D MMM YYYY';

export const formatDisplayDate = (date: moment.Moment | Date | undefined | null): string => {
  if (date === undefined || date === null) {
    return '';
  }

  // Wrap the input date with moment to ensure compatibility and use .format() to standardize the date presentation.
  const momentDate = moment(date);

  // Check if the moment date is valid before formatting.
  return momentDate.isValid() ? momentDate.format(DISPLAY_DATE_FORMAT) : '';
};

export const formatDisplayDateWithTime=(date: moment.Moment | Date | undefined | null)=>{
  if (date === undefined || date === null) {
    return '';
  }

  // Wrap the input date with moment to ensure compatibility and use .format() to standardize the date presentation.
  const momentDate = moment(date);

  // Check if the moment date is valid before formatting.
  return momentDate.isValid() ? `${formatDisplayDate(momentDate)} ${moment(momentDate).format('hh:mm A')}` : '';
}
import styles from '../../brand_page_properties.module.scss';
import { faBars, faBrowser } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ActionButton } from './ActionButton';
import { formatDisplayDate } from 'helpers/DateHelpers';

const HomepageBadge = () => {
  return (
    <div className={styles.homepage_badge}>
      <div className={styles.homepage_flag_summary_value}>Homepage</div>
    </div>
  );
};
const PageErrorBadge = () => {
  return (
    <div className={styles.error_badge}>
      <div className={styles.error_badge_text}>Error</div>
    </div>
  );
};
const SummaryValues = ({
  pageName,
  homepageFlag,
  url,
  pageStartDate,
  pageStopDate,
  brandStoreStartDate,
  brandStoreStopDate,
  inheritDate,
  pageHasErrors,
}) => {
  const displayDates = {
    from: formatDisplayDate(!inheritDate && pageStartDate ? new Date(pageStartDate) : new Date(brandStoreStartDate)),
    to: formatDisplayDate(!inheritDate && pageStopDate ? new Date(pageStopDate) : new Date(brandStoreStopDate)),
  };

  return (
    <div className={styles.summary_values}>
      <div className={styles.summary_value_flex_row}>
        <div className={styles.page_name_summary_value}>{pageName || 'No Page Name Set'}</div>
        {homepageFlag && <HomepageBadge />}
        {pageHasErrors && <PageErrorBadge />}
      </div>
      <div className={styles.summary_value_flex_row}>
        <div className={styles.url_date_summary_value}>{`${url || 'No URL set'}  •  ${displayDates.from} to ${
          displayDates.to
        }`}</div>
      </div>
    </div>
  );
};

export const PageSettingsSummary = ({
  pageName,
  isHomepage,
  url,
  onPageSave,
  onPageEdit,
  onPageRemove,
  editingPage,
  pageStartDate,
  pageStopDate,
  brandStoreStartDate,
  brandStoreStopDate,
  inheritDate,
  readOnly,
  pageHasErrors,
}) => {
  return (
    <div className={styles.page_settings_summary}>
      <FontAwesomeIcon
        icon={faBars}
        className={styles.summary_bars_icon}
        style={(isHomepage || readOnly) && { color: 'transparent' }}
      />
      <FontAwesomeIcon icon={faBrowser} className={styles.summary_page_icon} />
      <SummaryValues
        homepageFlag={isHomepage}
        pageName={pageName}
        url={url}
        pageStartDate={pageStartDate}
        pageStopDate={pageStopDate}
        brandStoreStartDate={brandStoreStartDate}
        brandStoreStopDate={brandStoreStopDate}
        inheritDate={inheritDate}
        className={styles.summary_values}
        pageHasErrors={pageHasErrors}
      />
      {editingPage ? (
        <ActionButton type={readOnly ? 'hide' : 'save'} onClick={onPageSave} className={styles.summary_button} />
      ) : (
        <>
          <ActionButton type={readOnly ? 'view' : 'edit'} onClick={onPageEdit} className={styles.summary_button} />
          {!isHomepage && !readOnly && (
            <ActionButton type={'remove'} onClick={onPageRemove} className={styles.summary_button} />
          )}
        </>
      )}
    </div>
  );
};

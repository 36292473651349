import axios from "../../axios";

export const createWallet = async (supplierID, data) => axios.post(`/wallets/${supplierID}`, data);

export const updateWallet = async (walletID, data) => axios.put(`/wallets/${walletID}`, data);

export const getSupplierWallets = async (supplierID) => axios.get(`/wallets/supplier-wallets`, { params: { supplier_id: supplierID }});

export const getActiveSupplierWallets = async function (supplierId) {
  return axios.get(`/wallets/active-supplier-wallets`, { params: { supplier_id: supplierId } });
};

export const getUserWallets = async (retailerID) => axios.get(`/wallets/user-wallets`, { params: { retailer_id: retailerID }});

export const getRetailerWallets = async () => axios.get("/wallets/retailer-wallets");

export const getWalletTransactions = async (walletID) => axios.get(`/wallets/${walletID}/transactions`);

export const submitWalletTransaction = async (walletID, data) => axios.post(`/wallets/${walletID}/transactions`, data);

export const getTransactionReportUrl = async (walletID) => axios.get(`/wallets/${walletID}/transactions/report`);

export const getSingleWallet = async (walletID) => axios.get(`/wallets/${walletID}/view`);

export const uploadWallets = async ( file, { onUploadProgress } = { onUploadProgress: () => {} }) => {
  const form = new FormData();
  form.append("file", file);
  return axios.post(`/wallets/upload`, form, {
    onUploadProgress: onUploadProgress,
    timeout: 0,
  });
};

export const downloadSample = async () => axios.get("/wallets/bulk-upload-sample", { responseType: "blob" });
